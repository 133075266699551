import React from 'react'
import "../styles/Footer.css";


function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        
      </div>
      <p> &copy; 2024 Tyler Audino</p>
    </div>
  );
}

export default Footer