import React from 'react'
import Footer from '../components/Footer';

function Contact() {
  return ( 
    <div className='contact page'>
      <div className='titleBox'>
        <h2>Let's get in touch!</h2>
        <br></br>
        <br/>
        <br/>
        <br/>
        <p>Coming soon...</p>
       
      </div>
    </div>
      
  )
}

export default Contact